import React, { FC } from "react";

import {
    Body,
    Button,
    ChevronLeftIcon,
    ChevronRightIcon,
    GridContainer,
    GridItem,
    breakpoints,
} from "@wayhome-uk/design-system-v2";
import { useRouter } from "next/router";
import styled from "styled-components";

interface IPagination {
    totalBlogPostCount: number;
    pageSize: number;
}

export const Pagination: FC<IPagination> = ({ totalBlogPostCount = 0, pageSize = 10 }) => {
    const { push, query } = useRouter();

    const lastPageSlice = Number(query.lastPageSlice) || 0;
    const isLastPage = lastPageSlice + pageSize >= totalBlogPostCount;
    const maxPageNumber = Math.ceil(totalBlogPostCount / pageSize);
    const pageNumber = lastPageSlice ? Math.ceil((lastPageSlice + 1) / pageSize) : 1;
    const showPaginator = totalBlogPostCount > pageSize;

    const handlePagination = (direction: "forward" | "backward") => {
        const sign = direction === "forward" ? 1 : -1;

        push({
            query: {
                ...query,
                lastPageSlice: lastPageSlice + pageSize * sign,
            },
        });
    };

    return showPaginator ? (
        <GridContainer>
            <PaginationWrapper $colSpan={{ md: 12 }} $marginTop={{ sm: 24 }} $marginBottom={{ sm: 32 }}>
                <Button
                    onClick={() => handlePagination("backward")}
                    type="button"
                    variant="text-button"
                    disabled={lastPageSlice === 0}
                >
                    <ChevronLeftIcon /> Previous
                </Button>
                <StyledBody>
                    {pageNumber} of {maxPageNumber}
                </StyledBody>
                <Button
                    onClick={() => {
                        handlePagination("forward");
                    }}
                    type="button"
                    variant="text-button"
                    disabled={isLastPage}
                >
                    Next
                    <ChevronRightIcon />
                </Button>
            </PaginationWrapper>
        </GridContainer>
    ) : null;
};

const StyledBody = styled(Body)`
    color: var(--neutral-04);
`;

const PaginationWrapper = styled(GridItem)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-48);

    @media (min-width: ${breakpoints.medium}) {
        justify-content: space-between;
    }

    button {
        display: flex;
        align-items: center;
        gap: var(--spacing-8);
        padding-inline: 0;

        &:disabled,
        &:disabled path {
            stroke: var(--neutral-04);
            color: var(--neutral-04);
            text-decoration: none;
        }
    }
`;
