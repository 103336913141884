import React, { FC } from "react";

import { urlForImage } from "@/sanity/lib/image";
import { Body, Heading2, Heading4, SmallTextBold, breakpoints } from "@wayhome-uk/design-system-v2";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";

type TVariation = "regular" | "highlight";

export interface IPostCardProps {
    imageUrl: string;
    category: string;
    title: string;
    subtitle: string;
    slug: string;
    variation?: TVariation;
}

export const PostCard: FC<IPostCardProps> = ({ imageUrl, slug, category, title, subtitle, variation = "regular" }) => {
    const isHighlight = variation === "highlight";
    const {
        query: { currentTab = "all" },
    } = useRouter();

    return (
        <StyledLink
            href={{
                pathname: "/blog/[slug]",
                query: {
                    slug,
                    returnTab: currentTab,
                },
            }}
            data-is-highlight={isHighlight}
        >
            <ImageContainer>
                <StyledBlogImage
                    priority={isHighlight}
                    width={500}
                    height={500}
                    src={imageUrl}
                    alt={title}
                    data-is-highlight={isHighlight}
                    loader={({ width, quality = 100 }) => urlForImage(imageUrl, width, quality)}
                />
            </ImageContainer>

            <StyledContent>
                <StyledCategory>{category}</StyledCategory>
                {isHighlight ? (
                    <Heading2 $marginTop={{ sm: 12 }}>{title}</Heading2>
                ) : (
                    <Heading4 as="h3" $marginTop={{ sm: 12 }}>
                        {title}
                    </Heading4>
                )}
                <StyledSubtitle $marginTop={{ sm: 12 }} $marginBottom={{ sm: 12 }}>
                    {subtitle}
                </StyledSubtitle>
            </StyledContent>
        </StyledLink>
    );
};

const StyledSubtitle = styled(Body)`
    color: var(--neutral-04);
`;

const StyledCategory = styled(SmallTextBold)`
    color: var(--primary-03);
    text-transform: uppercase;
`;

const StyledContent = styled.div`
    padding: var(--spacing-32);

    &:last-child {
        padding: var(--spacing-32);
    }
`;

const StyledLink = styled(Link)`
    display: block;
    border-radius: var(--spacing-4);
    overflow: hidden;
    text-decoration: none;
    background-color: var(--white);
    transition: transform 200ms ease-in-out;

    &:hover {
        transform: scale(1.005);
    }

    @media (min-width: ${breakpoints.medium}) {
        height: 100%;
    }

    @media (min-width: ${breakpoints.large}) {
        height: 100%;
        &[data-is-highlight="true"] {
            display: flex;
            flex-direction: row-reverse;
        }
    }
`;

const ImageContainer = styled.div`
    width: 100%;
`;

const StyledBlogImage = styled(Image)`
    width: 100%;
    height: 32rem;
    object-fit: cover;
    display: block;

    &[data-is-highlight="true"] {
        @media (min-width: ${breakpoints.medium}) {
            min-height: 100%;
            height: 100%;
        }
    }
`;
