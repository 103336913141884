import createImageUrlBuilder from "@sanity/image-url";

import { dataset, projectId } from "../env";

const imageBuilder = createImageUrlBuilder({
    projectId: projectId || "",
    dataset: dataset || "",
});

export const urlForImage = (source: string, width: number, quality: number) => {
    return imageBuilder?.image(source).width(width).quality(quality).auto("format").fit("max").url();
};
