import React from "react";

import { GridContainer, GridItem, ListItem, UnorderedList, breakpoints } from "@wayhome-uk/design-system-v2";
import styled from "styled-components";

import { IPostCardProps, PostCard } from "./post-card";

interface IPostCardListProps {
    posts: IPostCardProps[];
}

export const PostCardList = ({ posts }: IPostCardListProps) => {
    return (
        <GridContainer>
            <GridItem $colSpan={{ md: 12 }} $marginBottom={{ sm: 32, md: 48 }} as="article">
                <StyledUnorderedList>
                    {posts.map((post, key) => (
                        <StyledListItem key={key}>
                            <PostCard variation={key === 0 ? "highlight" : "regular"} {...post} />
                        </StyledListItem>
                    ))}
                </StyledUnorderedList>
            </GridItem>
        </GridContainer>
    );
};

const StyledUnorderedList = styled(UnorderedList)`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--spacing-32);
`;

const StyledListItem = styled(ListItem)`
    padding: 0;

    &::before {
        display: none;
    }

    grid-column: span 12;

    @media (min-width: ${breakpoints.medium}) {
        grid-column: span 6;

        &:first-child {
            grid-column: span 12;
        }
    }

    @media (min-width: ${breakpoints.large}) {
        grid-column: span 4;

        &:first-child {
            grid-column: span 12;
        }
    }
`;
