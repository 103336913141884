import React, { FC } from "react";

import { QueryParams } from "next-sanity";
import { useLiveQuery } from "next-sanity/preview";

import { IBlogPost } from "types/pages";

import { BLOG_POST_QUERY } from "../../../../sanity/lib/queries";
import { Post } from "./post";

export const PostPreview: FC<{ data: IBlogPost; params: QueryParams }> = ({ data, params = {} }) => {
    const [blogPost] = useLiveQuery<IBlogPost>(data, BLOG_POST_QUERY, params);

    return <Post data={blogPost} />;
};
