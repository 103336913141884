import React from "react";

import { Body, Button, GridContainer, GridItem, Heading4, breakpoints } from "@wayhome-uk/design-system-v2";
import { useAuthUser } from "@wayhome-uk/hooks";
import styled from "styled-components";

import HomeSVG from "../../icons/home.svg";
import { CtaSkeletonLoader } from "./cta-skeleton-loader";

const isDev = process.env.NEXT_PUBLIC_SANITY_DATASET === "development";

export const BlogCTA = () => {
    const { status, user } = useAuthUser();
    const isLoggedIn = Boolean(user?.uuid);
    const title = isLoggedIn ? "Complete the next step in your journey" : "Ready to become a homeowner?";

    const subtitle = isLoggedIn
        ? "Check your application status"
        : "Sign up today and join hundreds of people in their journey to buy a home with Wayhome.";

    const sigUpHref = isDev ? "https://www.futurewayhome.co.uk/suitability" : "https://www.wayhome.co.uk/suitability";

    const nextStepHref = isDev
        ? "https://www.futurewayhome.co.uk/application-process"
        : "https://www.wayhome.co.uk/application-process";

    const href = isLoggedIn ? nextStepHref : sigUpHref;

    const buttonText = isLoggedIn ? "See my next step" : "Sign Up";

    if (status === "pending" || status === "initializing") {
        return <CtaSkeletonLoader />;
    }

    return (
        <StyledCTABackground>
            <GridContainer>
                <StyledCTAGridItem $colSpan={{ sm: 1, md: 8 }} $colStart={{ sm: 1, md: 3 }}>
                    <CallToActionInfo>
                        <Heading4 $marginBottom={{ sm: 12 }}>{title}</Heading4>
                        <Body $marginBottom={{ sm: 12 }}>{subtitle}</Body>
                        <Button
                            variant="marketing"
                            as="a"
                            href={href}
                            rel="noopener noreferrer"
                            target="_blank"
                            $marginBottom={{ sm: 12 }}
                        >
                            {buttonText}
                        </Button>
                    </CallToActionInfo>

                    <SvgWrapper>
                        <HomeSVG />
                    </SvgWrapper>
                </StyledCTAGridItem>
            </GridContainer>
        </StyledCTABackground>
    );
};

const StyledCTABackground = styled.div`
    background-color: var(--primary-02);
`;

const StyledCTAGridItem = styled(GridItem)`
    display: flex;
`;

const CallToActionInfo = styled.div`
    padding-block: var(--spacing-32);
    width: 100%;

    @media (min-width: ${breakpoints.medium}) {
        width: max-content;
    }

    a {
        width: 100%;
        text-align: center;

        @media (min-width: ${breakpoints.medium}) {
            width: max-content;
            text-align: left;
        }
    }
`;

const SvgWrapper = styled.div`
    display: flex;
    padding-left: var(--spacing-16);

    & > svg {
        display: none;
        align-self: end;

        @media (min-width: ${breakpoints.medium}) {
            display: inline-block;
        }
    }
`;
