import React from "react";

import type { PortableTextComponents } from "@portabletext/react";
import {
    Body,
    Heading3,
    Heading4,
    Heading5,
    Link,
    ListItem,
    OrderedList,
    SmallText,
    UnorderedList,
} from "@wayhome-uk/design-system-v2";
import styled from "styled-components";

import { VideoMedia } from "components";
import { hasPortableTextValue, isDarkColor } from "components/page/sections/helpers";
import { PortableTextImage } from "components/portable-text-image";
import { SimpleTable } from "components/simple-table";

import { sharedTextStyle } from "./helpers";

export const components = (backgroundColor = "#ffffff"): PortableTextComponents => {
    return {
        marks: {
            link: ({ children, value = "/" }) => {
                const rel = !value.href.startsWith("/") ? "noreferrer noopener" : undefined;
                const target = !value.href.startsWith("/") ? "_blank" : undefined;

                return (
                    <StyledLink $backgroundColor={backgroundColor} href={value.href} rel={rel} target={target}>
                        {children}
                    </StyledLink>
                );
            },
        },
        block: {
            blockquote: ({ children }) => (
                <StyledBlockQuote>
                    <StyledQuote as="p" $backgroundColor={backgroundColor}>
                        {children}
                    </StyledQuote>
                </StyledBlockQuote>
            ),
            normal: ({ children }) => {
                if (!hasPortableTextValue(children)) {
                    return null;
                }

                return <StyledBody $backgroundColor={backgroundColor}>{children}</StyledBody>;
            },
            h5: ({ children }) => <StyledHeading5 $backgroundColor={backgroundColor}>{children}</StyledHeading5>,
            h4: ({ children }) => <StyledHeading4 $backgroundColor={backgroundColor}>{children}</StyledHeading4>,
            h3: ({ children }) => <StyledHeading3 $backgroundColor={backgroundColor}>{children}</StyledHeading3>,
            small: ({ children }) => <StyledSmallText $backgroundColor={backgroundColor}>{children}</StyledSmallText>,
        },
        list: {
            bullet: ({ children }) => (
                <StyledUnorderedList $backgroundColor={backgroundColor}>{children}</StyledUnorderedList>
            ),
            number: ({ children }) => (
                <StyledOrderedList $backgroundColor={backgroundColor}>{children}</StyledOrderedList>
            ),
        },
        listItem: {
            bullet: ({ children }) => <StyledListItem $backgroundColor={backgroundColor}>{children}</StyledListItem>,
            number: ({ children }) => <StyledListItem $backgroundColor={backgroundColor}>{children}</StyledListItem>,
        },
        types: {
            table: ({ value }) => <SimpleTable value={value} backgroundColor={backgroundColor} />,
            image: ({ value }) => <PortableTextImage value={value} />,
            video: ({ value }) => <VideoMedia isBlog videoUrl={value.videoUrl} title={value.videoTitle} />,
        },
    };
};

const StyledBody = styled(Body)<{ $backgroundColor: string }>`
    ${sharedTextStyle}
    padding-bottom: var(--spacing-16);

    &:last-child {
        padding-bottom: 0;
    }
`;

const StyledHeading3 = styled(Heading3)<{ $backgroundColor: string }>`
    ${sharedTextStyle}
    padding-bottom: var(--spacing-16);
`;

const StyledHeading4 = styled(Heading4)<{ $backgroundColor: string }>`
    ${sharedTextStyle}
    padding-bottom: var(--spacing-16);
`;

const StyledHeading5 = styled(Heading5)<{ $backgroundColor: string }>`
    ${sharedTextStyle}
    padding-bottom: var(--spacing-16);
`;

const StyledBlockQuote = styled(Heading4)<{ $backgroundColor: string }>`
    margin: 0;
    padding-bottom: var(--spacing-16);
`;

const StyledQuote = styled(Heading4)<{ $backgroundColor: string }>`
    ${sharedTextStyle}
    display: inline;
    padding-left: 3.5rem;
    position: relative;

    &::before {
        ${sharedTextStyle};
        content: "\201C";
        position: absolute;
        font-size: 6rem;
        top: -1.6rem;
        left: 0px;
    }
`;

const StyledListItem = styled(ListItem)`
    ${sharedTextStyle}

    &:last-child {
        padding-bottom: var(--spacing-16);
    }
`;

const StyledSmallText = styled(SmallText)`
    ${sharedTextStyle}
`;

const StyledUnorderedList = styled(UnorderedList)`
    ${sharedTextStyle}
`;

const StyledOrderedList = styled(OrderedList)`
    ${sharedTextStyle}
`;

const StyledLink = styled(Link)<{ $backgroundColor: string }>`
    color: ${({ $backgroundColor }) => (isDarkColor($backgroundColor) ? "var(--alert-03)" : "var(--primary-03)")};
`;
