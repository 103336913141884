import React from "react";

import { GridContainer, GridItem, breakpoints } from "@wayhome-uk/design-system-v2";
import styled, { css } from "styled-components";

export const CtaSkeletonLoader = () => {
    return (
        <StyledCTABackground>
            <GridContainer>
                <StyledCTAGridItem $colSpan={{ sm: 1, md: 8 }} $colStart={{ sm: 1, md: 3 }}>
                    <CallToActionInfo>
                        <StyledTitleSkeleton />
                        <StyledSubtitleSkeleton />
                        <StyledButtonSkeleton />
                    </CallToActionInfo>

                    <StyledSvgSkeleton />
                </StyledCTAGridItem>
            </GridContainer>
        </StyledCTABackground>
    );
};

const Mixin = css`
    background-color: var(--primary-01);
    border-radius: 1rem;
`;

const StyledTitleSkeleton = styled.div`
    ${Mixin};
    height: 3rem;
    width: 100%;

    @media (min-width: ${breakpoints.medium}) {
        width: 80%;
    }
`;

const StyledSubtitleSkeleton = styled.div`
    ${Mixin}
    height: 3rem;
    width: 100%;

    @media (min-width: ${breakpoints.medium}) {
        width: 60%;
    }
`;

const StyledButtonSkeleton = styled.div`
    ${Mixin}
    width: 100%;
    height: 5rem;

    @media (min-width: ${breakpoints.medium}) {
        width: 20rem;
    }
`;

const StyledSvgSkeleton = styled.div`
    ${Mixin}
    height: 15rem;
    width: 15rem;
    align-self: center;
    display: none;

    @media (min-width: ${breakpoints.medium}) {
        display: block;
    }
`;

const CallToActionInfo = styled.div`
    padding-block: var(--spacing-32);
    width: 100%;
    height: 100%;
    display: grid;
    gap: var(--spacing-12);
`;

const StyledCTABackground = styled.div`
    position: relative;
    overflow: hidden;
    background-color: var(--primary-02);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -150px;
        height: 100%;
        width: 150px;
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
        animation: loading 1.3s infinite;
    }

    @keyframes loading {
        0% {
            left: -150px;
        }
        50% {
            left: 100%;
        }
        100% {
            left: 100%;
        }
    }
`;

const StyledCTAGridItem = styled(GridItem)`
    display: flex;
    justify-content: space-between;
`;
