import React from "react";

import { Body, FullNameLogo, GridContainer, GridItem, breakpoints } from "@wayhome-uk/design-system-v2";
import styled from "styled-components";

import blogBackground from "./assets/blog-header-background-illustration.png";

export const BlogHeader = () => {
    return (
        <StyledSection>
            <GridContainer>
                <GridItem
                    $colSpan={{ sm: 1, md: 12 }}
                    $marginTop={{ sm: 48, md: 64 }}
                    $marginBottom={{ sm: 48, md: 64 }}
                >
                    <StyledLogoContainer>
                        <StyledWayHomeLogo /> <StyledWayHomeLearn as="h1">LEARN</StyledWayHomeLearn>
                    </StyledLogoContainer>
                </GridItem>
            </GridContainer>
        </StyledSection>
    );
};

const StyledSection = styled.section`
    background-color: var(--primary-01);
    background-image: url(${blogBackground.src});
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    min-height: 300px;

    @media (min-width: ${breakpoints.medium}) {
        background-size: auto 80%;
    }
`;

const StyledWayHomeLogo = styled(FullNameLogo)`
    width: 250px;
    height: auto;

    @media (min-width: ${breakpoints.medium}) {
        width: 300px;
        height: auto;
    }
`;

const StyledWayHomeLearn = styled(Body)`
    color: var(--primary-03);
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 2rem;
    align-self: flex-end;

    @media (min-width: ${breakpoints.medium}) {
        font-size: 3rem;
    }
`;

const StyledLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: var(--spacing-12);
`;
