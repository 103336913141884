import { groq } from "next-sanity";

const postQuery = groq`
    title,
    seo,
    subtitle,
    body[]{
        _type == "block" => {
            _type,
            _key,
            listItem,
            level,
            style,
            markDefs,
            children,
        },
        _type == "image" => {
            _type,
            _key,
            "imageUrl": asset->url,
            "title": asset->title,
        },
        _type == "table" => {
            _type,
            _key,
            rows[],
        },
        _type == "video" => {
            _type,
            _key,
            "videoUrl": asset->url,
            "videoTitle": asset->title,
        }
    },
    "slug": slug.current,
    "category": category->name,
    "updatedAt": _updatedAt,
`;

export const BLOG_POST_QUERY = groq`
    *[_type == "blogPost" && slug.current == $slug][0]{
        ${postQuery}
    }
`;

export const BLOG_POSTS_QUERY = groq`
    *[_type == "blogPost" && ($category == "all" || lower(category->name) == $category)]
    | order(_createdAt asc)[$lastPageSlice...$lastPageSlice + $pageSize]{
        "imageUrl": image.asset->url,
        ${postQuery}
    }
`;

export const BLOG_POSTS_COUNT_QUERY = groq`
    count(*[_type == "blogPost" && ($category == "all" || lower(category->name) == $category)]
    | order(_createdAt asc))
`;

export const BLOG_POST_SLUG_QUERY = groq`
    *[_type == "blogPost" && defined(slug.current)][].slug.current
`;

export const BLOG_POST_CATEGORY_QUERY = groq`
    *[_type == "blogPostCategories"] | order(orderRank).name
`;

const mediaQuery = groq`
    media {
        isVisibleOnMobile,
        imageLayout,
        videoLayout,
        "videoUrl": video.asset->url,
        "videoTitle": video.asset->title,
        "imageUrl": image.asset->url,
        "altText": image.asset->altText,
        "description": image.asset->description,
        "tags": image.asset->opt.media.tags[]->name.current,
        "title": image.asset->title,
    }
`;

const buttonQuery = groq`
    button {
        _type,
        label,
        buttonColor,
        "href": href->href,
        "isExternal": href->isExternal,
    }
`;

const textWithMediaQuery = groq`
    _type == "textWithMedia" => {
        _key,
        _type,
        title,
        body,
        backgroundColor,
        button.label != null => {
            ${buttonQuery},
            textBelowButton,
        },
        media.video.asset != null || media.image.asset != null => {
            ${mediaQuery},
        }
    }
`;

export const PAGES_SLUG_QUERY = groq`
    *[_type in ["pages", "legal", "marketing"] && defined(slug.current)][].slug.current
`;

export const PAGE_QUERY = groq`
    *[_type in ["pages", "legal", "marketing"] && slug.current == $slug][0]{
        _type == "pages" || _type == "marketing" => {
            _type,
            seo,
            firstSection[]{
                _type == "hero" => {
                    _key,
                    _type,
                    title,
                    subtitle,
                    button.label != null => {
                        ${buttonQuery}
                    },
                    media.video.asset != null || media.image.asset != null => {
                        ${mediaQuery}
                    },
                }
            },
            content[]{
                _type == "comparisonTable" => {
                    _type,
                    _key,
                    title,
                    highlightedColumn,
                    table,
                    tableFooter,
                },
                _type == "contentGrid" => {
                    _type,
                    _key,
                    heading,
                    backgroundColor,
                    contentGridList[] {
                        _key,
                        title,
                        subtitle,
                        image.asset != null => {
                            "imageUrl": image.asset->url,
                            "altText": image.asset->altText
                        },
                    }
                },
                _type == "messageTile" => {
                    _type,
                    _key,
                    backgroundColor,
                    messageType,
                    text,
                },
                _type == "imageBar" => {
                    _type,
                    _key,
                    title,
                    backgroundColor,
                    logos[] -> {
                        institutionName,
                        "institutionURL": href->href,
                        "institutionLogoURL": institutionLogo.asset->url,
                        institutionLogo {
                            asset->{
                                url
                            }
                        },
                    }
                },
                _type == "reviewCollections" => {
                    _type,
                    _key,
                    title,
                    backgroundColor,
                    reviews[] -> {
                        _id,
                        name,
                        description,
                        rating,
                        date,
                    }
                },
                ${textWithMediaQuery}
            }
        },
        _type == "legal" => {
            _type,
            seo,
            legalHero,
            content[]{
                ${textWithMediaQuery}
            }
        }
    }
`;
