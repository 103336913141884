import { isDarkColor } from "components/page/sections/helpers";

export const getImageDimensions = (id: string) => {
    const dimensions = id.split("-")[1];

    const [width, height] = dimensions.split("x").map((num: string) => parseInt(num, 10));
    const aspectRatio = width / height;

    return { width, height, aspectRatio };
};

export const sharedTextStyle = ({ $backgroundColor }: { $backgroundColor: string }) => ({
    color: isDarkColor($backgroundColor) ? "white" : "var(--neutral-05)",
});
