import React from "react";

import { RenderResult, render } from "@testing-library/react";
import userEvent, { UserEvent } from "@testing-library/user-event";
import { SystemNotification, SystemNotificationProvider } from "@wayhome-uk/design-system-v2";
import { AuthProvider } from "@wayhome-uk/hooks";
import { getAuth } from "firebase/auth";
import { RouterContext } from "next/dist/shared/lib/router-context.shared-runtime";
import { PrefetchOptions } from "next/dist/shared/lib/router/router";
import { NextRouter } from "next/router";

/* eslint-disable @typescript-eslint/no-empty-function */
const mockRouter: NextRouter = {
    asPath: "/",
    basePath: "",
    back: () => {},
    beforePopState: () => {},
    events: {
        emit: () => {},
        off: () => {},
        on: () => {},
    },
    isFallback: false,
    isLocaleDomain: false,
    isPreview: false,
    isReady: false,
    pathname: "/",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    prefetch: (url: string, asPath?: string, options?: PrefetchOptions) => Promise.resolve(),
    push: () => Promise.resolve(true),
    query: {},
    reload: () => {},
    replace: () => Promise.resolve(true),
    route: "/",
    forward: () => {},
};

type TDefaultProps = {
    router: Partial<NextRouter> | Record<string, never>;
    applicationsMigrationFlag?: boolean;
};

const defaultProps: TDefaultProps = {
    router: {},
};

type RenderRootResult = {
    user: UserEvent;
} & RenderResult;

export const renderRoot = (children: JSX.Element, props = defaultProps): RenderRootResult => {
    const container = render(
        <AuthProvider
            auth={getAuth()}
            apiBaseURL={process.env.NEXT_PUBLIC_API_BASE_URL || ""}
            applicationsMigrationFlag={props.applicationsMigrationFlag || false}
        >
            <SystemNotificationProvider>
                <div id="modal-root">
                    <RouterContext.Provider value={{ ...mockRouter, ...props.router }}>
                        {children}
                    </RouterContext.Provider>
                </div>
                <SystemNotification />
            </SystemNotificationProvider>
        </AuthProvider>,
    );

    return { user: userEvent.setup(), ...container };
};
