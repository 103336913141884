import React from "react";

import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import styled from "styled-components";

interface IPortableTextImageProps {
    value: {
        imageUrl: string;
        title: string;
    };
}

export const PortableTextImage = ({ value }: IPortableTextImageProps) => {
    return (
        <ImageWrapper>
            <StyledImage
                width={800}
                height={800}
                src={value.imageUrl}
                alt={value.title}
                loader={({ width, quality = 100 }) => urlForImage(value.imageUrl, width, quality)}
            />
        </ImageWrapper>
    );
};

const ImageWrapper = styled.div`
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledImage = styled(Image)`
    width: 100%;
    max-width: 80rem;
    height: auto;
`;
