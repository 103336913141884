import React, { FC } from "react";

import { GridContainer, GridItem, UnorderedList, breakpoints } from "@wayhome-uk/design-system-v2";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";

interface INavigationTabProps {
    blogPostCategories: string[];
}

export const NavigationTab: FC<INavigationTabProps> = ({ blogPostCategories }) => {
    const { query } = useRouter();

    const { currentTab = "all" } = query;

    const categories = [
        {
            label: "All",
            value: "all",
        },
        ...blogPostCategories.map((blogPostCategory) => ({
            label: blogPostCategory,
            value: blogPostCategory.toLowerCase(),
        })),
    ];

    return (
        <GridContainer>
            <GridItem $colSpan={{ sm: 12 }} $marginTop={{ sm: 48, md: 64 }} $marginBottom={{ sm: 48, md: 64 }}>
                <StyledUnorderedList>
                    {categories.map(({ label, value }) => (
                        <li key={label}>
                            <StyledLink
                                href={{
                                    query: {
                                        currentTab: value,
                                    },
                                }}
                                data-active={currentTab === value}
                            >
                                {label}
                            </StyledLink>
                        </li>
                    ))}
                </StyledUnorderedList>
            </GridItem>
        </GridContainer>
    );
};

const StyledUnorderedList = styled(UnorderedList)`
    display: flex;
    flex-wrap: wrap;
    background: var(--primary-03);
    border-radius: 3rem;
    padding: var(--spacing-4);

    @media (min-width: ${breakpoints.large}) {
        column-gap: 1rem;
    }
`;

const StyledLink = styled(Link)`
    color: var(--white);
    border-radius: 3rem;
    padding: var(--spacing-12) var(--spacing-24);
    display: inline-flex;
    text-decoration: none;
    width: max-content;

    &:hover {
        background-color: #ffffff15;
        color: var(--white);
    }

    &[data-active="true"] {
        background-color: var(--white);
        color: var(--primary-03);
    }
`;
