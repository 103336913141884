import React from "react";

import { Body } from "@wayhome-uk/design-system-v2";
import { ObjectItem } from "sanity";
import styled from "styled-components";

import { isDarkColor } from "components/page/sections/helpers";
import { sharedTextStyle } from "utils";

interface IRow extends ObjectItem {
    cells: string[];
}

interface ISimpleTableProps {
    value: {
        rows: IRow[];
    };
    backgroundColor: string;
}

export const SimpleTable = ({ value, backgroundColor }: ISimpleTableProps) => {
    const [head, ...rows] = value.rows;
    const isBiDirectional = head.cells[0].length === 0;

    return (
        <Table $backgroundColor={backgroundColor} as="table">
            <thead>
                <tr>
                    {head.cells.map((cell: string) => (
                        <th key={cell}>{cell}</th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {rows.map((row, rowIndex: number) => (
                    <tr key={rowIndex}>
                        {row.cells.map((cell: string, cellIndex: number) => {
                            const Component = isBiDirectional && cellIndex === 0 ? "th" : "td";
                            return (
                                <Component key={cell} data-label={head.cells[cellIndex]} title={head.cells[cellIndex]}>
                                    {cell}
                                </Component>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

const Table = styled(Body)<{ $backgroundColor: string }>`
    ${sharedTextStyle}
    border-collapse: collapse;
    text-align: left;
    margin-bottom: var(--spacing-16);

    td,
    th,
    tr {
        padding: 0.5rem 1rem;
        border: ${({ $backgroundColor }) =>
            isDarkColor($backgroundColor) ? "1px solid white" : "1px solid var(--neutral-04)"};
    }
`;
