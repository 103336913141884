import React, { FC } from "react";

import { PortableText } from "@portabletext/react";
import {
    Body,
    Button,
    ChevronLeftIcon,
    GridContainer,
    GridItem,
    Heading1,
    SmallText,
    SmallTextBold,
} from "@wayhome-uk/design-system-v2";
import { format, parseISO } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import styled from "styled-components";

import { MetaTags } from "components";
import { BlogCTA } from "components/blog";
import { IBlogPost } from "types/pages";
import { components } from "utils/portable-text-components";

export const Post: FC<{ data: IBlogPost }> = ({ data }) => {
    const {
        push,
        query: { returnTab = "all" },
    } = useRouter();
    const { showBlogCta } = useFlags();

    const { seo, updatedAt, title, subtitle, body, category } = data;

    return (
        <>
            <MetaTags title={seo.title} description={seo.description} />

            <StyledIntroWrapper>
                <GridContainer>
                    <GridItem $colSpan={{ sm: 1, md: 8 }} $colStart={{ sm: 1, md: 3 }}>
                        <BackButton variant="text-button" onClick={() => push(`/blog?currentTab=${returnTab}`)}>
                            <ChevronLeftIcon /> Back
                        </BackButton>

                        <StyledCategory $marginTop={{ sm: 24 }}>{category}</StyledCategory>

                        <Heading1 $marginTop={{ sm: 24 }}>{title}</Heading1>

                        <StyledDateDisplay>
                            <SmallTextBold>Last Updated</SmallTextBold>
                            <SmallText>{format(parseISO(updatedAt), "EEE MMM dd yyyy")}</SmallText>
                        </StyledDateDisplay>

                        <StyledSubtitle $marginTop={{ sm: 24 }}>{subtitle}</StyledSubtitle>
                    </GridItem>
                </GridContainer>
            </StyledIntroWrapper>

            <GridContainer>
                <StyledContentContainer $colSpan={{ sm: 1, md: 8 }} $colStart={{ sm: 1, md: 3 }}>
                    <PortableText value={body} components={components()} />
                </StyledContentContainer>
            </GridContainer>

            {showBlogCta ? <BlogCTA /> : null}
        </>
    );
};

const StyledDateDisplay = styled.div`
    display: flex;
    gap: var(--spacing-8);
    margin-top: var(--spacing-12);
`;

const StyledContentContainer = styled(GridItem)`
    padding-block: var(--spacing-48);
`;

const StyledSubtitle = styled(Body)`
    color: var(--neutral-04);
`;

const StyledCategory = styled(SmallTextBold)`
    color: var(--primary-03);
    text-transform: uppercase;
`;

const StyledIntroWrapper = styled.div`
    background-color: var(--white);
    padding-top: var(--spacing-12);
    padding-bottom: var(--spacing-48);
`;

const BackButton = styled(Button)`
    display: flex;
    align-items: center;
    padding-left: 0;

    & > svg {
        position: relative;
        bottom: var(--spacing-2);
        left: calc(-1 * var(--spacing-4));
    }
`;
