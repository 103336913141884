import { ReactNode } from "react";

import { TColourLabel, TImageLayout, TMedia, TVideoLayout } from "types/pages";

export const isLeft = (layout?: TImageLayout | TVideoLayout) => layout?.includes("left");
export const isRight = (layout?: TImageLayout | TVideoLayout) => layout?.includes("right");
export const isBottom = (layout?: TImageLayout | TVideoLayout) => layout?.includes("bottom");
export const isCenter = (layout?: TImageLayout | TVideoLayout) => {
    const centerVariants = ["center right", "center left"];
    return centerVariants.some((value) => value === layout);
};
export const isDarkColor = (color: string): boolean => {
    const darkColors = ["#025a65", "#023841"];

    return darkColors.includes(color);
};
export const isVideo = (layout?: TVideoLayout) => {
    const videoVariants: TVideoLayout[] = ["center", "left", "right"];
    return videoVariants.some((value) => value === layout);
};

type TColStartConfig = {
    mediaType?: "video" | "image";
    layout?: TImageLayout | TVideoLayout;
};
export const calculateColStart = (config: TColStartConfig = {}) => {
    const { layout = "bottom right", mediaType } = config;

    if (layout === "center") {
        return { sm: "auto" };
    }

    if (mediaType === "video" && isRight(layout)) {
        return { sm: 1, md: 7 };
    }

    if (mediaType === "image" && isRight(layout)) {
        return { sm: 1, md: 9 };
    }

    if (!mediaType && layout === "left") {
        return { sm: 1, md: 7 };
    }

    if (!mediaType && isLeft(layout)) {
        return { sm: 1, md: 5 };
    }

    return { sm: 1, md: 2 };
};

type TColSpanConfig = {
    media?: TMedia;
};
export const calculateColSpan = (config?: TColSpanConfig) => {
    const { media } = config ?? {};

    if (media?.videoLayout === "center") {
        return { sm: 12 };
    }

    if (media?.videoUrl) {
        return { sm: 12, md: 5 };
    }

    if (media?.imageUrl) {
        return { sm: 1, md: 3 };
    }

    return { sm: 1, md: 7, lg: 6 };
};

export const getButtonVariant = (colorLabel: Partial<TColourLabel>) => {
    if (colorLabel === "Primary-03") {
        return "primary";
    }

    if (colorLabel === "Primary-01") {
        return "secondary";
    }

    return "marketing";
};

export const removeInvisibleCharacters = (text?: string) => {
    try {
        const invisibleCharRegex = /[\u200B-\u200D\uFEFF]/g;
        return text?.replace(invisibleCharRegex, "").trim() || "";
    } catch {
        return "";
    }
};

export const hasPortableTextValue = (node?: ReactNode) => {
    if (!Array.isArray(node) || node?.length === 0) {
        return false;
    }

    const isStringContent = (str: string) => {
        return typeof str === "string" && removeInvisibleCharacters(str) !== "";
    };

    return node.some((value) => isStringContent(value) || Boolean(removeInvisibleCharacters(value?.props?.text)));
};
